import http from "../http";
import Service from "../../Service";

/**
 * @type BuyerService
 */
export default class BuyerService extends Service {

    create(buyer) {
		return http.post(`/admin/clients/${buyer.client.id}/buyers`, buyer, {
            headers: this.getHeaders()
        });
	}

    update(buyer) {
		return http.put(`/admin/clients/${buyer.client.id}/buyers/${buyer.id}`, buyer, {
            headers: this.getHeaders()
        });
	}

    save(buyer) {
		return buyer.id ? this.update(buyer) : this.create(buyer)
	}

    suspend(id) {
		return http.delete(`/admin/clients/registrations/${id}/suspend`, {
			headers: this.getHeaders()
		});
	}

    remove(client_id, id) {
		return http.delete(`/admin/clients/${client_id}/buyers/${id}`, {
			headers: this.getHeaders()
		});
	}

}
