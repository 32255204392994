import http from "../Http";
import Service from "../Service";

export default class StoreService extends Service {

	all(client_id) {
		return http.get("loja", {
			headers: this.getHeaders(),
			params: { cliente_id: client_id }
		});
    }

	update(client_id, id, data) {
		return http.put(`loja/${id}`, this.parse_params(data, client_id), {
			headers: this.getHeaders()
		});
	}

	parse_params(store, cli_id) {
		return {
			'cliente_id': cli_id,
			'numero': store.loj_numeroLoja,
			'nome': store.loj_descricao,
			'razao_social': store.loj_razao,
			'nome_fantasia': store.loj_nomeFantasia,
			'tipo': store.loj_matriz,
			'cnpj': store.loj_cnpj.replace(/[^0-9]/g, ''),
			'telefone': store.loj_telefone.replace(/[^0-9]/g, ''),
			'endereco': store.loj_endereco,
			'uf': store.state ? store.state.est_id : store.est_id,
			'cidade': store.city ? store.city.cid_id : store.cid_id,
			'bairro': store.est_id,
			'cep': store.loj_cep.replace(/[^0-9]/g, ''),
			'email': store.loj_email,
		}
	}

}
