<template>
    <div>
        <div class="direct-modal-table-content">
            <div class="direct-page-table-header">
                <div class="direct-page-table-header-text direct-name-width">Nome</div>
                <div class="direct-page-table-header-text direct-login-width">Login</div>
                <div class="direct-page-table-header-text direct-phone-width">Telefone</div>
                <div class="direct-page-table-header-text direct-password-width">Fornecedor</div>
                <div class="direct-page-table-header-text direct-created-at-width">Criado em</div>
                <div class="direct-page-table-header-text direct-actions-width">Ações</div>
          </div>
          <div v-for="(client, idx) in filteredList" :key="client.id" :class="getSpecialBackground(idx)">
                <div v-if="client.usu_status == 1 && client.usu_excluido == 0" class="direct-page-table-row">
                    <div class="direct-page-table-desc-column direct-name-width" :title="client.usu_nome">
                        <div class="direct-page-table-header-mobile">Nome</div>
                        {{ client.usu_nome }}
                    </div>
                    <div class="direct-page-table-desc-column direct-login-width" :title="client.usu_login">
                        <div class="direct-page-table-header-mobile">Login</div>
                        {{ client.usu_login }}
                    </div>
                    <div class="direct-page-table-desc-column direct-phone-width">
                        <div class="direct-page-table-header-mobile">Telefone</div>
                        <cc-whatsapp :phone="client.usu_telefone" />
                    </div>
                    <div class="direct-page-table-desc-column direct-password-width" :title="client.for_nome">
                        <div class="direct-page-table-header-mobile">Fornecedor</div>
                        {{ client.for_nome }}
                    </div>
                    <div class="direct-page-table-desc-column direct-created-at-width">
                        <div class="direct-page-table-header-mobile">Criado em</div>
                        {{ client.usu_dataCadastro | formatDate }}
                    </div>
                    <div class="direct-page-table-desc-column direct-actions-width">
                        <div class="direct-page-table-header-mobile">Ações</div>
                        <span class="material-icons-outlined direct-edit-icon"
                                title="Editar Vendedor" 
                                @click="edit(client)">
                            edit
                        </span>
                    </div>
                </div>  
          </div>
        </div>

        <cc-loader-full v-if="isLoading"/>
        <DirectSaleFormModalVue v-if="editUser" :client="client" :service="clientService" :user="editUser" :closeAndUpdate="() => {editUser = undefined; closeModal(); isLoading = true; loadPage()}" :close="() => {editUser = undefined; closeModal()}"/>
    </div>
</template>

<script>
import ClientService from "@/services/v3/client.service";
import DirectSaleFormModalVue from "./DirectSaleFormModal.vue";

    export default {
        name: "DirectSale",
        components:{
            DirectSaleFormModalVue
        },
        props: ['client', 'searchText', 'openModal', 'closeModal'],
        computed:{
            filteredList(){
                if (this.searchText) return this.clients.filter(element => {
                    return element.usu_nome.toLowerCase().includes(this.searchText.toLowerCase())
                })
                else return this.clients
            }
        },
        watch: {
            openModal(value){ 
                if (value) this.editUser = {cli_id: this.client.id}
            }
        },
        mounted () {
            this.loadPage()
        },
        data () {
            return {
                clientService: new ClientService(),
                isLoading: true,
                clients: [],
                editUser: undefined
            }
        },
        methods: {
            getSpecialBackground (idx) { if (idx % 2 != 0) return 'page-table-line-special' },
            edit(value) { this.editUser = value },
            loadPage(){
                this.clientService.getDirectSales(this.client.id).then(response => {
                    this.clients = response.data[0].data
                    this.isLoading = false
                })
            }
        }
    }
</script>

<style>
    .direct-modal-table-content {
        margin: 1em 2em;
    }
    .direct-page-table-header {
        background: #FFFEFC;
        border: 0.5px solid #E5E5E5;
        font-weight: 400;
        color: #605F5F;
        display: flex;
        padding: 1em 0em;
    }
    .direct-page-table-header-text {
        padding-left: 10px;
        font-weight: 600; 
        font-size: 1.19em;
        color: #505050;
    }
    .direct-name-width {
        width: 17%;
        text-overflow: ellipsis;
        overflow-x: hidden;
    }
    .direct-login-width {
        flex: 1;
        text-overflow: ellipsis;
        overflow-x: hidden;
    }
    .direct-phone-width {
        width: 14%;
    }
    .direct-store-width {
        width: 20%;
    }
    .direct-password-width {
        width: 14%;
        text-overflow: ellipsis;
        overflow-x: hidden;
    }
    .direct-created-at-width {
        width: 14%;
    }
    .direct-actions-width {
        width: 12%;
    }
    .direct-page-table-line-special {  
        background: #F7F7F7;
    }
    .direct-page-table-header-mobile{display: none;}
    .direct-page-table-row {
        display: flex;
        align-items: center;
        font-weight: 300;
        font-size: 1em;
        color: #605F5F;
    }
    .direct-page-table-desc-column {
        padding: 0.5em 0.7em;
    }
    .direct-edit-icon {
        color: #505050;
        cursor: pointer;
    }
    @media only screen and (max-width: 750px) {
        .direct-page-table-header{display: none;}
        .direct-page-table-row{
            flex-direction: column;
            margin-bottom: 3vh;
        }
        .direct-name-width {            width: 100%;        }
        .direct-login-width {
            flex: unset;
            width: 100%;
        }
        .direct-phone-width {     width: 100%;    }
        .direct-store-width {     width: 100%;    }
        .direct-password-width {  width: 100%;    }
        .direct-created-at-width { width: 100%;   }
        .direct-actions-width {    width: 100%;   }
        .direct-page-table-desc-column {
            display: flex; 
            justify-content: space-between;
            font-size: 2vw;
        }
        .direct-page-table-header-mobile{
            display: flex;
            font-size: 1.2em;
            font-weight: bold;
        }
    }
    @media only screen and (max-width: 600px) {
        .direct-page-table-desc-column {
            font-size: 2.5vw;
        }
    }
    @media only screen and (max-width: 450px) {
        .direct-page-table-desc-column {
            font-size: 4vw;
        }
    }
</style>