import http from "../http";
import Service from "../../Service";

/**
 * @type SysConfService
 */
export default class SysConfService extends Service {

    load() {
		return http.get(`/admin/management/sysconf`, {
			headers: this.getHeaders()
		});
	}

    deactivate_state_campaign(uf) {
		return http.delete(`/admin/management/campaigns/deactivate`, {
            headers: this.getHeaders()
        });
	}

    activate_state_campaign(fus) {
		return http.put(`/admin/management/campaigns/activate`, { fus }, {
            headers: this.getHeaders()
        });
	}

    upload_banner(fu, banner) {
        const data = new FormData();
        data.append("banner", banner);
        return http.post(`admin/management/sysconf/campaigns/banner/${fu}`, data, {
            headers: this.getHeaders()
        });
    }

    update_banners(fu, banners) {
        return http.put(`admin/management/sysconf/campaigns/banner/${fu}`, { banners: banners }, {
            headers: this.getHeaders()
        });
    }

    load_user_external(user) {
		return http.get(`/admin/management/sysconf/user_external/${user}`, {
			headers: this.getHeaders()
		});
	}

    update_user_external(user){
        return http.put(`admin/management/sysconf/user_external/${user.id}`, user.settings, {
            headers: this.getHeaders()
        });
    }

}
