<template>
  <div :style="getWidth()">
    <div class="input-title">{{title}}</div>
    <div class="input-containear" v-on:click="openSelect()">
      <input type="text" v-model="productSearch" class="input-value" :placeholder="placeholder">
      <span v-if="!isSelectOpen" class="material-icons input-icon">search</span>
      <span v-else class="material-icons input-icon" v-on:click.stop="closeSelect()">close</span>
    </div>
    <div v-if="isSelectOpen">
      <div v-if="isLoading" class="select-body" :style="getSelectWidth()">
          <cc-loader style="height: 10vh !important; margin-top: 3vh" :show_txt="false"/>
      </div>
      <div v-else class="select-body" :style="getSelectWidth()">
        <div v-for="(x, idx) in list" :key="idx">
          <div v-on:click="callAction(x)" class="select-body-line">
            <div class="select-body-line-text">{{x.text}}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isSelectOpen" class="iput-close-body" v-on:click="closeSelect()"></div>
  </div>
</template>

<script>
import ProviderService from "@/services/v3/provider.service";

export default {
  name: 'DirectSaleSelect',
  props: ['title', 'action', 'selectWidth', 'width', 'propPlaceholder'],
  data () {
    return {
      isSelectOpen: false,
      productSearch: '',
      placeholder: "",
      list: [],
      service: new ProviderService(),
      isLoading: true
    }
  },
  mounted () {
    this.placeholder = this.propPlaceholder
    this.load()
  },
  methods: {
    load () {
      let params = {
          name: this.productSearch,
          state: null,
          page: 1
      }
      this.list = []
      this.service.load(params).then(response => {
        response.data.data.forEach(element => {
          this.list.push({text: element.name, value: element})
        });
        this.isLoading = false
      })
    },
    getWidth(){return 'width: ' + this.width},
    getSelectWidth(){return 'width: ' + this.selectWidth},
    openSelect () { this.isSelectOpen = true },
    closeSelect () { this.isSelectOpen = false },
    callAction(value) {
      this.action(value.value)
      this.placeholder = value.text
      this.isSelectOpen = false
      this.productSearch = value.text
    }
  },
  watch: {
    productSearch () {
      if (!this.isLoading){
        this.isLoading = true
        setTimeout(() => {
          this.load()
        }, 3000);
      }
    }
  }
}
</script>

<style scoped>
.input-title{
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #898989;
}
.input-containear{
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 12px;
  height: 6vh;
  display: flex;
}
.input-value{
  border: none;
  flex: 1;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.15px;
  color: #B3B3B3;
}
.input-value:focus{
  border: none;
  outline: none;
}
.input-icon{
  color: #B3B3B3;
  cursor: pointer;
}
.input-select-value{
  border: none;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.15px;
  color: #B3B3B3;
  flex: 1;
  height: 24px;
}
.input-select-value:focus{
  border: none;
  outline: none;
}
.select-body{
  position: absolute;
  z-index: 3;
  background-color: white;
  overflow-y: scroll;
  max-height: 35vh;
}
.select-body-line{
  display: flex;
  align-items: center;
  padding: 10px 2px;
  cursor: pointer;
  flex: 1;
}
.select-body-line:hover{ background-color: rgba(255, 247, 247, 0.75); }
.select-body-line-text{
  font-weight: 300;
  font-size: 15px;
  color: #505050;
  margin-left: 5px;
}
.iput-close-body{
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
}
</style>