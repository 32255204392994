<template>
    <div id="seller" class="col">
        <div class="list-group">
            <ValidationObserver v-slot="{ handleSubmit }">
                <form action="" v-if="buyer" @submit.prevent="handleSubmit( save )" style="margin: 0em 1em;">
                    <div class="inputs-container justify-content-center">
                        <div class="col">
                          <!-- <label>Usuário Externo:</label> -->
                          <div class="input-title">
                            Usuário Externo
                          </div>
                          <!-- <cc-input type="text" v-model="buyer.settings.user_external"></cc-input> -->
                          <div class="input-container">
                            <input type="text" v-model="buyer.settings.user_external" class="input-value" placeholder="Digite o usuário externo">
                          </div>
                        </div>
                        <div class="col">
                          <!-- <label>Senha:</label> -->
                          <div class="input-title">
                            Senha
                          </div>
                          <!-- <cc-input type="password" v-model="buyer.settings.user_pass"></cc-input> -->
                          <div class="input-container">
                            <input type="text" v-model="buyer.settings.user_pass" class="input-value" placeholder="Digite a senha">
                          </div>
                        </div>
                    </div>

                    <div class=" justify-content-center" style="margin: 2em 0;">
                      <div class="col">
                        <!-- <label>Subdominio:</label> -->
                        <div class="input-title">
                          Subdomínio
                        </div>
                        <!-- <cc-input type="text" v-model="buyer.settings.user_domain"></cc-input> -->
                        <div class="input-container">
                          <input type="text" v-model="buyer.settings.user_domain" class="input-value" placeholder="Digite o subdomínio">
                        </div>
                      </div>
                    </div>

                    <button ref="send-seller" type="submit" style="display:none;"></button>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>

import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import BuyerService from "@/services/v3/clients/buyer.service";

export default {
    mixins: [ loaderMixin ],
    components: {
        ValidationProvider,
		ValidationObserver
    },
    props: [ "buyer", "profiles", "stores", "secondary_profiles" ],
    data() {
        return {
            svc: new BuyerService()
        }
    },
    methods: {
        
    }
}
</script>

<style scoped>
.input-title {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #898989;
}
.input-container {
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 12px;
  margin-top: 5px;
  display: flex;
}
.inputs-container{display: flex;}
.input-value {
  border: none;
  flex: 1;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.15px;
  color: #B3B3B3;
}
.input-value:focus {
  border: none;
  outline: none;
}

@media only screen and (max-width: 650px) {
  .inputs-container{
    flex-direction: column;
    gap: 1vh;
  }
}
</style>
