<template>
    <div @click="close()" class="direct-sales-modal-mask">
        <div @click.stop="" class="direct-sales-modal-header">
            {{user.usu_id ? 'Editar usuário venda direta' : 'Criar usuário venda direta'}}
            <span @click="close()" class="material-icons direct-sales-close-icon">cancel</span>
        </div>
        <div @click.stop="" class="direct-sales-modal-body">
            <div class="direct-sales-input-container">
                <StandardInput class="direct-sales-input" :auto="true" :action="setName" :value="user.usu_nome" title="Nome"  :type="'text'"/>
                <StandardInput class="direct-sales-input" :auto="true" :action="setEmail" :value="user.usu_email" title="Email"  :type="'text'"/>
            </div>
            <div class="direct-sales-input-container">
                <StandardInput class="direct-sales-input" :auto="true" :action="setLogin" :value="user.usu_login" title="Login"  :type="'text'"/>
                <StandardInput class="direct-sales-input" :auto="true" :action="setPassword" :password="true" :value="user.usu_senha" title="Senha"  :type="'text'"/>
            </div>
            <div class="direct-sales-input-container">
                <StandardInput class="direct-sales-input" :mask="'(##) #.####-####'" :auto="true" :action="setPhone" :value="user.usu_telefone" title="Telefone"  :type="'text'"/>
                <StandardInputListFiltered selectWidth="40vw" class="direct-sales-input" title="Loja" :propPlaceholder="selectStore" :action="setStore" :auto="true" :list="storeList" :type="'select'"/>
            </div>
            <div class="direct-sales-input-container">
                <SupliersSelect selectWidth="40vw" class="direct-sales-input" title="Fornecedor" :propPlaceholder="user.for_nome" :action="setSuplier" :auto="true" />
            </div>
            <div class="direct-sales-buttons-container">
                <StandardButton :action="close" text="Cancelar"/>
                <StandardButton :action="save" text="Salvar"/>
            </div>
        </div>

        <cc-loader-full v-if="isLoading"/>
    </div>
</template>

<script>
import StandardButton from '@/components/ui/buttons/StandardButton.vue'
import StandardInputListFiltered from '@/components/ui/inputs/StandardInputListFiltered.vue'
import SupliersSelect from '@/components/ui/inputs/DirectSaleSelect.vue'
import StandardInput from '@/components/ui/inputs/StandardInputV2.vue'


    export default {
        name: "DirectSaleFormModal",
        props: ['close', 'closeAndUpdate', 'user', 'service', 'client'],
        components:{
            StandardInput,
            StandardButton,
            StandardInputListFiltered,
            SupliersSelect
        },
        data () {
            return {
                isLoading: false,
                storeList: [{text: 'Todos', value: null}],
                selectStore: 'Selecione a empresa',
            }
        },
        mounted () {
            this.isLoading = true
            this.service.load_stores(this.client.id).then(response => {
                response.data.forEach(element => {
                    this.storeList.push({text: element.trading_name, value: element.id})
                    if (element.id == this.user.usu_idLoja) this.selectStore = element.trading_name
                });
                this.isLoading = false
            })
        },
        methods: {
            save() {
                this.isLoading = true
                this.service.UpdateDirectSales(this.user).then(() => {
                    this.isLoading = false
                    this.closeAndUpdate()
                })
            },
            setName(value) {this.user.usu_nome = value},
            setEmail(value) {this.user.usu_email = value},
            setLogin(value) {this.user.usu_login = value},
            setPassword(value) {this.user.usu_senha = value},
            setPhone(value) {this.user.usu_telefone = value.replace('(','').replace(')','').replace('.','').replace('-','').replace(' ','')},
            setStore(value) {this.user.usu_idLoja = value},
            setSuplier(value) {this.user.for_id = value.id}
        }
    }
</script>

<style>
    .direct-sales-modal-mask {
        position: fixed;
        z-index: 5;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        transition: opacity 0.3s ease;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .direct-sales-modal-header{
        width: 95vw;
        background-color: var(--primary-color);
        border-radius: 10px 10px 0 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1vh 2vw;
        color: #ffffff;
        font-weight: 500;
        font-size: 32px;
    }
    .direct-sales-close-icon{
        font-size: 30px;
        cursor: pointer;
    }
    .direct-sales-modal-body{
        background-color: white;
        width: 95vw;
        height: 95vh;
        border-radius: 0 0 10px 10px;
        overflow: auto;
        padding: 1vh 2vw;
    }
    .direct-sales-input-container{
        display: flex;
        justify-content: space-between;
        margin-top: 5vh;
    }
    .direct-sales-input{
        width: 45%;
    }
    .direct-sales-buttons-container{
        display: flex;
        justify-content: flex-end;
        gap: 5vw;
        margin-top: 15vh;
    }

    @media only screen and (max-width: 650px) {
        .direct-sales-modal-header {width: 110vw;}
        .direct-sales-modal-body{width: 110vw;}
        .direct-sales-input-container {flex-direction: column; gap: 1.5vh;}
        .direct-sales-input{width: 100%;}
        .button-containear {width: 45%;}
    }
</style>